import { useMutation } from '@tanstack/react-query';
import { AppointmentsGateway } from '../../../../services/http/appointments-gateway';
import { useGetTraveller } from '../useGetTraveller';
import { useOffice } from '../../../hooks/useOffice/useOffice';
import { CreateAppointmentPayload, TCreateAppointmentResponse } from './types';

const useCreateAppointment = () => {
  const office = useOffice();

  const { data: travellerData } = useGetTraveller({ isEnabled: false });

  const mutation = useMutation(
    async ({
      timezone,
      date,
      start,
    }: {
      timezone: string;
      date: string;
      start: string;
    }) => {
      const appointmentPayload: CreateAppointmentPayload = {
        eventId: Number(office.gCalendar.eventId),
        contact: {
          type: 'traveller',
          id: Number(travellerData?.result.cid),
        },
        timezone,
        date,
        start,
      };

      return await AppointmentsGateway.post<TCreateAppointmentResponse>(
        `/public/booking/session`,
        appointmentPayload
      );
    }
  );

  return { mutation, isLoading: mutation.isLoading, error: mutation.error };
};

export { useCreateAppointment };
