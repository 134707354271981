import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Dayjs } from 'dayjs';

type GCalendarState = {
  lastEnabledDate: Dayjs | null;
};

const initialState: GCalendarState = {
  lastEnabledDate: null,
};

const gcalendarSlice = createSlice({
  name: 'gcalendar',
  initialState,
  reducers: {
    setLastEnabledDate(state, action: PayloadAction<Dayjs | null>) {
      state.lastEnabledDate = action.payload;
    },
  },
});

export const { setLastEnabledDate } = gcalendarSlice.actions;
export default gcalendarSlice.reducer;
