import { Dispatch, SetStateAction } from 'react';
import { Accomodation } from './accomodation/accomodation';
import { TravellerNumber } from './traveller-number';
import { TDropDownItem } from 'lib/types/dropdown';
import { Room, RoomFormat, SelectedRoom } from 'lib/types/trip';

type Props = {
  minCompanion: number;
  maxCompanion: number;
  isLoading: boolean;
  travellerDropdown: TDropDownItem | null;
  setTravellerDropdown: Dispatch<SetStateAction<TDropDownItem | null>>;
  rooms: Room[] | null | undefined;
  setSelectedRooms: Dispatch<SetStateAction<SelectedRoom[]>>;
  selectedRooms: SelectedRoom[];
  defaultRoom: RoomFormat[] | null;
  onChangeTravellerDropdown: any;
};

const RoomForm = ({
  minCompanion,
  maxCompanion,
  isLoading,
  travellerDropdown,
  setTravellerDropdown,
  setSelectedRooms,
  rooms,
  selectedRooms,
  defaultRoom,
  onChangeTravellerDropdown,
}: Props) => {
  return (
    <div className="p-5 md:p-10 flex flex-col gap-5">
      <h2 className="font-semibold text-[1.5rem] md:text-2xl text-dark-800">
        Rooms
      </h2>

      <TravellerNumber
        minCompanion={minCompanion}
        maxCompanion={maxCompanion}
        isLoading={isLoading}
        travellerDropdown={travellerDropdown}
        setTravellerDropdown={setTravellerDropdown}
        onChangeTravellerDropdown={onChangeTravellerDropdown}
        rooms={rooms}
      />
      <Accomodation
        rooms={rooms}
        travellerDropdown={travellerDropdown}
        setSelectedRooms={setSelectedRooms}
        selectedRooms={selectedRooms}
        defaultRoom={defaultRoom}
      />
    </div>
  );
};

export { RoomForm };
