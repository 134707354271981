import { useEffect, useState } from 'react';
import { RoomForm } from './forms';
import { RoomSubmit } from './room-submit';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { RoomFormat } from 'lib/types/trip';
import { RoomValidation } from './room-validation';
import { ContinueCta } from 'components/MobileCta/Continue';
import { useDevice } from 'lib/hooks/useDevice';
import { TRoomSubmit } from 'lib/types/room';
import { ChangeDate as ChangeDateModal } from '../../changeDate/ChangeDate';
import { SelectionAvailability } from 'lib/types/availability';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { setNoOfTravellers, setRawRooms } from 'state/slices/room';
import { useIsMutating } from '@tanstack/react-query';

const RoomStage = ({
  selectedRooms,
  setSelectedRooms,
  submitRoom,
  roomHasError,
  isRoomLoading,
  roomValidationMessage,
  travellerCountDropdown,
  setTravellerCountDropdown,
  submitRoomIsGotToGo: isGotToGo,
}: TRoomSubmit) => {
  const [isChooseDateOpen, setIsChooseDateOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const hasDepartureDate = useAppSelector(
    (state) => state.product.hasDepartureDate
  );
  const roomsState = useAppSelector((state) => state.product.rooms);

  const { isMobile } = useDevice();
  const [defaultRooms, setDefaultRooms] = useState<RoomFormat[] | null>(null);

  const { isLoading: tripDataLoading, data: tripData } = useTripQuery();
  const isGotToGoLoading = useIsMutating({ mutationKey: ['got-to-go'] });
  const isGotToGoProcessing =
    !!isGotToGoLoading || (isGotToGo && isRoomLoading);

  const onChangeTravellerDropdown = () => {
    setSelectedRooms([]);
    setDefaultRooms(null);
  };
  const isSubmitRoomLoading = isRoomLoading && !isGotToGo;

  const closeChooseDateModal = () => setIsChooseDateOpen(false);

  const checkSubmit = () => {
    if (hasDepartureDate) {
      submitRoom();
    } else {
      setIsChooseDateOpen(true);
    }
  };

  const afterSubmitModal = (
    flexibleDeparture: boolean,
    selectedAvailability: SelectionAvailability
  ) => {
    submitRoom(flexibleDeparture, selectedAvailability);
  };

  useEffect(() => {
    dispatch(setRawRooms(selectedRooms));
  }, [dispatch, selectedRooms]);

  useEffect(() => {
    dispatch(setNoOfTravellers(Number(travellerCountDropdown?.value)));
  }, [dispatch, travellerCountDropdown]);

  useEffect(() => {
    const tempDefault = roomsState
      .filter((item) => item.type === 'room')
      .map((item) => item.data);
    setDefaultRooms(tempDefault);
  }, [roomsState]);

  return (
    <div className="bg-light-600 md:border border-light-800 md:shadow-[0px_5px_5px_0px_#17181a0d] rounded-md">
      <RoomForm
        minCompanion={
          tripData && tripData.result.trip.min_companion
            ? tripData?.result.trip.min_companion + 1
            : 1
        }
        maxCompanion={
          tripData && tripData.result.trip.max_companion
            ? tripData.result.trip.max_companion + 1
            : 8
        }
        isLoading={tripDataLoading}
        travellerDropdown={travellerCountDropdown}
        setTravellerDropdown={setTravellerCountDropdown}
        rooms={tripData?.result.rooms}
        setSelectedRooms={setSelectedRooms}
        selectedRooms={selectedRooms}
        defaultRoom={defaultRooms}
        onChangeTravellerDropdown={onChangeTravellerDropdown}
      />
      <RoomValidation
        type={roomHasError ? 'error' : 'success'}
        message={roomValidationMessage}
      />
      <div className="hidden md:block">
        <hr className="border-none h-px w-full bg-light-800" />
        <RoomSubmit
          clickHandler={() => checkSubmit()}
          isDisabled={
            roomHasError || isSubmitRoomLoading || isGotToGoProcessing
          }
          isLoading={isSubmitRoomLoading}
          submitButtonText={hasDepartureDate ? 'Continue' : 'Set Date'}
        />
      </div>

      {isMobile && (
        <div className="fixed z-50 bottom-0 left-0 right-0 bg-light-600">
          <ContinueCta
            submitHandler={() => checkSubmit()}
            isDisabled={
              roomHasError || isSubmitRoomLoading || isGotToGoProcessing
            }
            isLoading={isSubmitRoomLoading}
            submitButtonText={hasDepartureDate ? 'Continue' : 'Set Date'}
          />
        </div>
      )}
      <ChangeDateModal
        isOpen={isChooseDateOpen}
        close={closeChooseDateModal}
        afterSubmit={afterSubmitModal}
      />
    </div>
  );
};

export { RoomStage };
