import { useEffect, useState } from 'react';

import { AppToastService } from '../Toast/AppToastService';
import AppointmentStatus from './AppoitmentStatus';
import { GwatCalendar } from './GwatCalendar';
import { TCreateAppointmentResponse } from '@/lib/react-query/Queries/gCalendar/types';
import { useGetEventDataQuery } from '@/lib/react-query/Queries/gCalendar/useGetEventDataQuery';

const AppointmentCalendar = () => {
  const { data: eventData } = useGetEventDataQuery();

  // TODO: get duration from backend
  const [duration, setDuration] = useState(15);

  const [appointment, setAppointment] = useState<TCreateAppointmentResponse>();

  const onAppointmentCreated = (result: TCreateAppointmentResponse) => {
    setAppointment(result);
  };

  useEffect(() => {
    if (eventData) {
      setDuration(eventData.duration);
    }
  }, [eventData]);

  const onCreateAppointmentError = (err: any) => {
    AppToastService.error(err.message);
    // TODO: handle error + Sentry
  };

  if (appointment) {
    return <AppointmentStatus appointment={appointment} duration={duration} />;
  }

  return (
    <GwatCalendar
      onAppointmentCreated={onAppointmentCreated}
      onCreateAppointmentError={onCreateAppointmentError}
      eventData={eventData}
    />
  );
};

export default AppointmentCalendar;
